import { useMainStore } from "~/stores/main";
import Accounting from "~/helpers/Accounting";
import Cookies from "js-cookie";
import md5 from "md5";

export default {
    logout(router) {
        const store = useMainStore();
        store.updateToken(null);
        store.updateUserDetails(null, null, null);
        Cookies.remove("token");
        router.push("/login");
    },
    validateConnectedUser(router) {
        return new Promise(resolve => {
            Accounting.get("/expenses/expense-reports/me")
                .then(res => {
                    if (res.data.last_name === null) {
                        router.push("/welcome");
                    } else {
                        useMainStore().updateUserDetails(
                            res.data.first_name + " " + res.data.last_name,
                            "https://www.gravatar.com/avatar/" + md5(res.data.email),
                            res.data.uuid
                        );
                        router.push("/");
                    }
                })
                .catch(() => {
                    this.logout(router);
                })
                .finally(() => {
                    resolve();
                });
        });

    },
    verifyLogin(token, router) {
        useMainStore().updateToken(token);
        Cookies.set("token", token, 60 * 60 * 2);
        return this.validateConnectedUser(router);
    }
};